// prettier-ignore
import { Button, Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Modal, Radio, RadioGroup, Select, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import { useState, useEffect, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import CloseIcon from '@mui/icons-material/Close';
import styles from './styles';
import { NumericFormat } from 'react-number-format';

const BaseModal = ({
  classes,
  title,
  productName,
  currency = 'USD',
  newTask,
  setNewTask,
  modalButton,
  openCreateTask,
  setOpenCreateTask,
  selectGoalUnit,
  setSelectGoalUnit,
  textGoalUnit,
  setTextGoalUnit,
  textActionButton,
  handleActionButton,
  handleCancelButton,
  children,
  disabled = false,
}) => {
  const intl = useIntl();
  const [quantifiable, setQuantifiable] = useState(true);
  const unitList = intl.messages['projectInfo.product.goalUnit'];
  const formatDate = intl.messages['projectInfo.product.dateFormat'];
  const unitExample = intl.messages['projectInfo.product.goalUnitExample'];

  useEffect(() => {
    if (!!newTask.id) {
      if (newTask.quantifiable) {
        if (!selectGoalUnit && unitList[newTask.goalUnit] === undefined) {
          setSelectGoalUnit('other');
          setTextGoalUnit(newTask.goalUnit);
        } else {
          setSelectGoalUnit(newTask.goalUnit);
        }
        setQuantifiable(false);
      }
    }
  }, [newTask]);

  const handleTaskChange = prop => event => {
    setNewTask({ ...newTask, [prop]: event.target.value });
  };

  const handleDateChange = prop => event => {
    setNewTask({ ...newTask, [prop]: new Date(event).toISOString() });
  };

  const handleGoalChange = value => {
    setNewTask({ ...newTask, goal: value.replace(/[^0-9]/g, '') });
  };

  const handleUnitChange = value => {
    setSelectGoalUnit(value);
    if (value !== 'other') {
      setNewTask({ ...newTask, goalUnit: value });
    } else {
      setNewTask({ ...newTask, goalUnit: '' });
    }
  };

  const handleQuantifiableChange = prop => event => {
    const bool = JSON.parse(event.target.value);
    if (bool) {
      setSelectGoalUnit(null);
      setTextGoalUnit(null);
      setNewTask({ ...newTask, [prop]: !bool, goal: 0, goalUnit: '' });
    } else {
      setNewTask({
        ...newTask,
        [prop]: !bool,
        goal: '',
        goalDescription: '',
      });
    }
    setQuantifiable(bool);
  };

  const handleTextGoalUnit = value => {
    setTextGoalUnit(value);
    setNewTask({ ...newTask, goalUnit: value });
  };

  const handleCloseModal = () => {
    setOpenCreateTask(false);
    handleCancelButton();
  };

  return (
    <>
      {modalButton}
      <Modal open={openCreateTask} onClose={handleCloseModal}>
        <Grid className={`${classes.task}-modal`}>
          <Grid container className={`${classes.task}_head`}>
            <Grid item xs={11}>
              <Typography variant='h4' color='text.secondary'>
                {title}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                className={`${classes.task}_head-button`}
                color='primary'
                aria-label='close task modal'
                onClick={handleCancelButton}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='inherit' color='text.secondary'>
                {intl.messages['mainContract.requiredAsteriskInfo']}{' '}
                <Typography component='span' color='red'>
                  *
                </Typography>
                .
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider flexItem className={`${classes.task}_head-divider`} />
            </Grid>
          </Grid>
          <Grid container className={`${classes.task}_body`}>
            <TextField
              id='description'
              label={productName}
              value={newTask.description}
              onChange={handleTaskChange('description')}
              fullWidth
              InputLabelProps={{ shrink: true }}
              multiline
              required
              size='small'
              rows={3}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={
                  <FormattedMessage id='projectInfo.product.plannedStartDate' defaultMessage='Planned Start Date' />
                }
                id='plannedStartDate'
                inputFormat={formatDate}
                value={newTask.plannedStartDate}
                onChange={handleDateChange('plannedStartDate')}
                renderInput={params => (
                  <TextField {...params} size='small' fullWidth required InputLabelProps={{ shrink: true }} />
                )}
              />
              <DatePicker
                label={<FormattedMessage id='projectInfo.product.plannedEndDate' defaultMessage='Planned End Date' />}
                id='plannedStartDate'
                inputFormat={formatDate}
                value={newTask.plannedCompleteDate}
                onChange={handleDateChange('plannedCompleteDate')}
                renderInput={params => (
                  <TextField {...params} size='small' fullWidth required InputLabelProps={{ shrink: true }} />
                )}
              />
            </LocalizationProvider>
            <NumericFormat
              allowLeadingZeros
              thousandSeparator=','
              customInput={TextField}
              id='budget'
              label={<FormattedMessage id='projectInfo.product.budget' defaultMessage='Budget' />}
              value={newTask.budget}
              onChange={handleTaskChange('budget')}
              size='small'
              fullWidth
              required
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: <InputAdornment position='start'>{currency || 'USD'}</InputAdornment>,
              }}
            />
            <FormControl>
              <RadioGroup
                row
                aria-labelledby='radio-buttons-group'
                name='radio-buttons-group'
                value={quantifiable}
                onChange={handleQuantifiableChange('quantifiable')}
              >
                <FormControlLabel
                  className={`${classes.task}_radioButton`}
                  value={true}
                  control={<Radio />}
                  label={<FormattedMessage id='projectInfo.product.target' defaultMessage='Target' />}
                />
                <FormControlLabel
                  className={`${classes.task}_radioButton`}
                  value={false}
                  control={<Radio />}
                  label={<FormattedMessage id='projectInfo.product.unit' defaultMessage='Unit' />}
                />
              </RadioGroup>
            </FormControl>
            {!quantifiable ? (
              <Grid container className={`${classes.task}_goal`}>
                <Grid container className={`${classes.task}_goal-title`}>
                  <Typography variant='subtitle2' color='text.secondary'>
                    <FormattedMessage id='projectInfo.product.goal' defaultMessage='Goal' />
                  </Typography>
                </Grid>
                <Grid className={`${classes.task}_goal-value`}>
                  <TextField
                    label={<FormattedMessage id='projectInfo.product.goal' defaultMessage='Goal' />}
                    id='goal'
                    value={newTask.goal}
                    onChange={event => handleGoalChange(event.target.value)}
                    size='small'
                    required
                  />
                </Grid>
                <FormControl size='small' className={`${classes.task}_goal-unit`}>
                  <Select
                    id='goalUnit'
                    value={selectGoalUnit || ''}
                    onChange={event => handleUnitChange(event.target.value)}
                    displayEmpty
                    required
                    renderValue={
                      !!selectGoalUnit
                        ? undefined
                        : () => (
                            <div className={`${classes.task}-placeholder`}>
                              {<FormattedMessage id='projectInfo.product.selectUnit' defaultMessage='Select unit' />}
                            </div>
                          )
                    }
                  >
                    {Object.entries(unitList).map(([key, value]) => (
                      <MenuItem key={`select_${key}`} value={key}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              <TextField
                id='target'
                label={<FormattedMessage id='projectInfo.product.target' defaultMessage='Target' />}
                value={newTask.goalDescription}
                onChange={handleTaskChange('goalDescription')}
                size='small'
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
                multiline
                rows={3}
              />
            )}
            {selectGoalUnit === 'other' && !quantifiable && (
              <TextField
                id='other'
                label={<FormattedMessage id='projectInfo.product.unit' defaultMessage='Unit' />}
                value={textGoalUnit || ''}
                placeholder={unitExample}
                onChange={event => handleTextGoalUnit(event.target.value)}
                size='small'
                fullWidth
                required
                InputLabelProps={{ shrink: true }}
              />
            )}
            {children}
          </Grid>
          <Grid container className={`${classes.task}_buttons`}>
            <Button
              variant='contained'
              onClick={handleActionButton}
              disabled={
                !newTask.description ||
                !newTask.plannedCompleteDate ||
                !newTask.budget ||
                !newTask.responsible ||
                ((newTask.goal <= 0 || !newTask.goalUnit) && (newTask.goal !== 0 || !newTask.goalDescription)) ||
                disabled
              }
            >
              {textActionButton}
            </Button>
            <Button variant='outlined' onClick={handleCancelButton}>
              <FormattedMessage id='projectInfo.product.cancel' defaultMessage={'Cancel'} />
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default withStyles(styles)(BaseModal);
